import Vue from "vue";
import router from "@/router";
import axios from "axios";
import moment from "moment";

import { getStore,removeStore} from "@/utils/global";
import { notNeedMachineCode, notShowLoading, notShowToast, notSignatureData } from "@/server/rules";

const v = new Vue();
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers["Content-Type"] = "application/json";

const { VUE_APP_BASEURL } = process.env;

const config = {
    baseURL: process.env.NODE_ENV === "development" ? `/api` : `${VUE_APP_BASEURL}api/`,
    timeout: 30 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};
const _axios = axios.create(config);

function qianming(params) {
    return window.paramSign(params);
}

_axios.interceptors.request.use(
    config => {
        // 使用loading还是barloading
        if (!notShowLoading(config.url)) v.$loading({ fullscreen: true, text: "加载中" });
        // else v.$bar.start();

        // 请求必传参数
        const params = {
            machine_code: notNeedMachineCode ? "WeChat-SA" : "", // 机器编号，微信公众号固定传：WeChat-SA
            hospital_sn: process.env.VUE_APP_SN, // 机器编号，微信公众号固定传：WeChat-SA
            timestamp: parseInt(moment().valueOf() / 1000), // 时间戳
        };
        const info = JSON.parse(getStore(`info`));
        if (info?.token) config.headers.Authorization = info?.token;
        params.patient_id = info?.id;

        // 更新配置项
        if (!notSignatureData(config.url)) config.data = { ...config.data, ...params };

        // 获取签名
        const obj = notSignatureData(config.url) ? qianming({
            "nonce-str": String(parseInt(new Date().getTime() / 1000)),
            timestamp: parseInt(new Date().getTime() / 1000),
        }) || {} : qianming(config.data) || {};

        // 在请求地址最后拼接签名
        config.url = config.url + `?signature=${obj.signature}`;

        console.log("config", config.data);
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

_axios.interceptors.response.use(
    response => {
        v.$cleanLoading();
        const // 声明通用常量
            { data, config } = response,
            { status, message, result } = data,
            { url } = config;
        if (response.status == 200) {
            return new Promise((resolve, reject) => {
                if (status == 200) {
                    // 正常返回
                    resolve(data);
                } else {
                    if (!notShowToast(config.url)) v.$toast(data.message);
                    if(status === 401) {
                        removeStore('info')
                        router.push({ path: "/login" });
                    }
                    reject(data);
                }

            });
        }
    },
    error => {
        v.$cleanLoading();
        v.$toast("网络错误，请稍后重试！");
        console.error("响应异常=>", error);

        return Promise.reject(error);
    },
);

Plugin.install = function(Vue, options) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            },
        },
        $axios: {
            get() {
                return _axios;
            },
        },
    });
};

Vue.use(Plugin);

export default Plugin;
