<template>
    <div v-if="show" id="loading">
        <div style="z-index: 9999">
            <van-loading color="#00ab87" size="60px" text-size="20px" vertical>
                {{ text }}
            </van-loading>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            text: "",
        };
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
#loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    margin: auto;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .7);;
    border: 1px #c9c9c9 dashed;
}

::v-deep .van-loading {
    .van-loading__text {
        font-family: PingFangSC-Regular;
        color: $theme-color;
    }
}

</style>
