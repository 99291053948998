import Vue from "vue";
import { Button } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { Image as VanImage } from "vant";
import { Lazyload } from "vant";
import { Loading } from "vant";
import { Tab, Tabs } from "vant";
import { Card } from "vant";
import { Grid, GridItem } from "vant";
import { Icon } from "vant";
import { Cell, CellGroup } from "vant";
import { Popup } from "vant";
import { Form } from "vant";
import { Field } from "vant";
import { DatetimePicker } from "vant";
import { Picker } from "vant";
import { Toast } from "vant";
import { RadioGroup, Radio } from "vant";
import { Sticky } from "vant";
import { Area } from "vant";
import { ActionSheet } from "vant";
import { Divider } from "vant";
import { SwipeCell } from "vant";
import { Dialog } from "vant";
import { Calendar } from "vant";
import { Collapse, CollapseItem } from "vant";
import { Sidebar, SidebarItem } from "vant";
import { Search } from "vant";
import { Empty } from "vant";
import { DropdownMenu, DropdownItem } from "vant";
import { Col, Row } from "vant";
import { Tag } from "vant";
import { Switch } from "vant";
import { PullRefresh } from "vant";
import { List } from "vant";
import { Checkbox, CheckboxGroup } from "vant";
import { Rate } from "vant";
import { progress } from "vant";
import { Swipe, SwipeItem } from "vant";
import { Uploader,Overlay } from 'vant';

Vue.use(Lazyload);
Vue.use(Rate);
Vue.use(List);
Vue.use(Sticky);
Vue.use(PullRefresh);
Vue.use(Switch);
Vue.use(Tag);
Vue.use(Empty);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Calendar);
Vue.use(Search);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Dialog);
Vue.use(SwipeCell);
Vue.use(Divider);
Vue.use(ActionSheet);
Vue.use(Area);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Toast);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(Field);
Vue.use(Form);
Vue.use(Popup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Icon);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Card);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Loading);
Vue.use(Lazyload);
Vue.use(VanImage);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Button);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(progress);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Uploader);
Vue.use(Overlay);
