export const needPatientID = url => {
    const needAPI = ["guide/getGuideMenu"];
    return needAPI.some(v => url.includes(v));
};
export const notNeedMachineCode = url => {
    const needAPI = ["guide/getGuideMenu"];
    return needAPI.some(v => url.includes(v));
};

export const notShowToast = url => {
    const needAPI = ["guide/record"];
    return needAPI.some(v => url.includes(v));
};

export const notShowLoading = url => {
    const needAPI = ["upload/imageByBase64"];
    return needAPI.some(v => url.includes(v));
};
export const notSignatureData = url => {
    const needAPI = ["upload/image", "upload/file"];
    return needAPI.some(v => url.includes(v));
};