export default [
    {
        path: "/service",
        name: "service",
        component: () => import("views/service/service"),
        meta: {
            title: "服务",
            notNeedPadding: true,
        },
    },
    {
        path: "/collect",
        name: "collect",
        component: () => import("views/collect/collect"),
        meta: {
            title: "记录",
            notNeedPadding: true,
        },
    },
    {
        path: "/evaluate",
        name: "evaluate",
        component: () => import("views/evaluate/evaluate"),
        meta: {
            title: "评价",
            notShowTabBar: false,
        },
    },
    {
        path: "/performance",
        name: "performance",
        component: () => import("views/performance/performance"),
        meta: {
            title: "绩效",
            notShowTabBar: false,
            notNeedPadding:true
        },
    },
    {
        path: "/mine",
        name: "mine",
        component: () => import("views/mine/mine"),
        meta: {
            title: "我的",
            notNeedPadding: true,
        },
    },
];
