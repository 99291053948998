/**
 * 存入本地存储
 * @param {string} name key值
 * @param {*} content 存储的内容
 */
export const setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== "string") {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
};

/**
 * 获取本地存储的某个参数
 * @param {string} name key值
 */
export const getStore = name => {
    if (!name) return;
    return window.localStorage.getItem(name);
};

/**
 * 删除本地存储的某个参数
 * @param {string} name key值
 */
export const removeStore = name => {
    if (!name) return;
    return window.localStorage.removeItem(name);
};

/** 微信重新登录 */
export const reload = () => {
    const { VUE_APP_SN, VUE_APP_BASEURL, NODE_ENV } = process.env;
    const { origin, pathname, hash, host, href } = window.location;
    let redirect_uri;
    if (NODE_ENV !== "production")
        redirect_uri = encodeURIComponent(`http://${host}${pathname}${hash}`);
    else redirect_uri = encodeURIComponent(`${origin}${pathname}${hash}`);
    const url = `${VUE_APP_BASEURL}api/weixin/h5Login/${VUE_APP_SN}?redirect_uri=${redirect_uri}`;
    if (!href.includes("paySuccess")) window.location.href = url;
};

// 获取参数
export const getQueryString = name => {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = "?".concat(window.location.href.split("?")[1]).substr(1).match(reg);
    if (r != null) {
        if (unescape(r[2]).includes("#")) return unescape(r[2]).split("#")[0];
        else return unescape(r[2]);
    }
    return null;
};
