const manageFiles = require.context(".", true, /\.js$/);

let configRouters = [];
manageFiles.keys().forEach(key => {
    if (key === "./index.js" || key === "./importModule.js") {
        return;
    } //    如果是当前文件，则跳过
    if (Object.keys(manageFiles(key)).length > 0)
        configRouters = configRouters.concat(manageFiles(key).default); // 读取出文件中的default模块
});
export default configRouters; // 抛出一个Vue-router期待的结构的数组
