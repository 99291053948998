<template>
    <van-tabbar v-model="active" active-color="#00ab87">
        <template v-for="(item, index) of tabbarList">
            <van-tabbar-item :key="index" @click="pushPage(item.path)">
                <span>{{ item.title }}</span>
                <template #icon="props">
                    <img :src="props.active ? item.active : item.inactive" />
                </template>
            </van-tabbar-item>
        </template>
    </van-tabbar>
</template>

<script>
export default {
    data() {
        return {
            active: 0,
            tabbarList: [
                {
                    title: "服务",
                    active: require("@/assets/tabbar/service_active.png"),
                    inactive: require("@/assets/tabbar/service_grey.png"),
                    path: "service",
                },
                {
                    title: "记录",
                    active: require("@/assets/tabbar/collect_active.png"),
                    inactive: require("@/assets/tabbar/collect_grey.png"),
                    path: "collect",
                },
                {
                    title: "评价",
                    active: require("@/assets/tabbar/star_active.png"),
                    inactive: require("@/assets/tabbar/star_grey.png"),
                    path: "evaluate",
                },
                {
                    title: "绩效",
                    active: require("@/assets/tabbar/performance_active.png"),
                    inactive: require("@/assets/tabbar/performance_grey.png"),
                    path: "performance",
                },
                {
                    title: "我的",
                    active: require("@/assets/tabbar/mine_active.png"),
                    inactive: require("@/assets/tabbar/mine_grey.png"),
                    path: "mine",
                },
            ],
        };
    },
    created() {
        // 刷新后根据当前路由回显tabbar高亮
        this.tabbarList.forEach((item, index) => {
            if (window.location.href.includes(item.path)) this.active = index;
        });
    },
    methods: {
        pushPage(path) {
            this.$router.push({ name: path });
        },
    },
};
</script>

<style lang="scss" scoped>
.tabbar_container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 48px;
    background-color: #ffffff;
}
</style>
