export default [
    {
        path: "/serviceDetails",
        name: "serviceDetails",
        component: () => import("views/service/serviceDetails"),
        meta: {
            title: "信息填写",
            customTitle: true,
            notShowTabBar: true,
            notNeedPadding:true
        },
    },
    {
        path: "/reviewList",
        name: "reviewList",
        component: () => import("views/collect/reviewList"),
        meta: {
            title: "审核列表",
            customTitle: true,
            notShowTabBar: true,
        },
    },
    {
        path: "/reviewDetails",
        name: "reviewDetails",
        component: () => import("views/collect/reviewDetails"),
        meta: {
            title: "审核详情",
            customTitle: true,
            notShowTabBar: true,
        },
    },
    {
        path: "/performanceType",
        name: "performanceType",
        component: () => import("views/performance/performanceType"),
        meta: {
            title: "绩效指标",
            notShowTabBar: true,
        },
    },
    {
        path: "/performanceList",
        name: "performanceList",
        component: () => import("views/performance/performanceList"),
        meta: {
            title: "指标条件",
            notShowTabBar: true,
        },
    },{
        path: "/addPerformance",
        name: "addPerformance",
        component: () => import("views/performance/addPerformance"),
        meta: {
            title: "添加",
            notShowTabBar: true,
        },
    },
];
