import Vue from "vue";
import VueRouter from "vue-router";
import routers from "./module/importModule";
import { getStore } from "@/utils/global";

Vue.use(VueRouter);

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

const { BASE_URL } = process.env;

const routes = [
    {
        path: "/",
        redirect: "/service",
    },
    ...routers,
];

const router = new VueRouter({
    mode: "hash",
    base: BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.meta.notNeedLogin || getStore(`info`)) next();
    else if (window.location.href.includes("login")) next();
    else next("/login");
});
router.afterEach((to, from) => {
    if (!to.meta.customTitle) window.document.title = process.env.NODE_ENV !== "production" ? (to.meta.title + "(测试)") : to.meta.title;
});

export default router;
