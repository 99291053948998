export default [
    {
        path: "/login",
        name: "login",
        component: () => import("views/login/login"),
        meta: {
            title: "社工登录",
            notShowTabBar: true,
            notNeedLogin: true,
        },
    },
    {
        path: "/register",
        name: "register",
        component: () => import("views/register/register"),
        meta: {
            title: "注册",
            notShowTabBar: true,
        },
        children: [
            {
                path: "/uploadImg",
                name: "uploadImg",
                component: () =>
                    import("views/register/registerChild/uploadImg"),
                meta: {
                    title: "注册-社工照片上传",
                    notShowTabBar: true,
                    notNeedPadding: true,
                    notNeedLogin: true,
                },
            },
            {
                path: "/inputInfo",
                name: "inputInfo",
                component: () =>
                    import("views/register/registerChild/inputInfo"),
                meta: {
                    title: "注册-社工信息录入",
                    notShowTabBar: true,
                    notNeedPadding: true,
                    notNeedLogin: true,
                },
            },
            {
                path: "/setPassword",
                name: "setPassword",
                component: () =>
                    import("views/register/registerChild/setPassword"),
                meta: {
                    title: "注册-密码设置",
                    notShowTabBar: true,
                    notNeedPadding: true,
                    notNeedLogin: true,
                },
            },
            {
                path: "/registerNotice",
                name: "registerNotice",
                component: () =>
                    import("views/register/registerChild/registerNotice"),
                meta: {
                    title: "注册审核",
                    notShowTabBar: true,
                    notNeedPadding: true,
                    notNeedLogin: true,
                },
            },
        ],
    },
];
