import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import "@/utils/vant.js";
import vconsole from "vconsole";
import "./server/axios";
import loading from "@/components/Loading";
import "vant/lib/index.css";
import * as VueJsonp from 'vue-jsonp'

Vue.config.productionTip = false;
Vue.use(loading);
Vue.use(VueJsonp);
/** 解构参数 */
const { NODE_ENV, VUE_APP_BASEURL } = process.env;
const logColor = "color:#e100fa;font-weight:700;font-size:14px;";

if (NODE_ENV !== "production") {
    // const envType = new Map([
    //     ["development", "服务器开发"],
    //     ["test", "测试开发"],
    //     ["dev", "测试开发"],
    //     ["production", "正式环境"],
    // ]);
    if (NODE_ENV !== "development") new vconsole();
    // console.log(
    //     `%c 开发环境=> ${NODE_ENV} - ${envType.get(NODE_ENV)}`,
    //     logColor,
    // );
    // console.log(`%c 接口地址=> ${VUE_APP_BASEURL}`, logColor);
    // console.log(`%c 接口文档=> ${VUE_APP_BASEURL}/apidoc/index.html`, logColor);
}
Vue.prototype.$moment = moment;
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app");
