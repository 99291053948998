export default {
	setRegisterPercentage(state, data) {
		state.registerPercentage = data;
	},
	setRegisterStep(state, data) {
		state.registerStep = data;
	},
	setTabsActive(state, data) {
		state.tabsActive = data;
	},
};
