<template>
    <div
        id="app"
        :style="{ padding: $route.meta.notNeedPadding ? '0' : '0px  11px' ,paddingBottom: $route.meta.notShowTabBar ? '0' :'80px'}"
    >
        <section v-if="$route.meta.notShowTabBar">
            <router-view></router-view>
        </section>
        <section v-else>
            <router-view></router-view>
            <Tabbar></Tabbar>
        </section>
    </div>
</template>

<script>
import Tabbar from "@/components/tabbar";

export default {
    components: {
        Tabbar,
    },
};
</script>

<style lang="scss">
@import "common/reset";

html,
body {
    background-color: #f1f1f1;
    //background-color: #ffffff;
}

#app {
    position: relative;
}
</style>
